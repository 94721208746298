import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import teste from '../../../img/teste.png'
import logo from '../../../img/SEEC - vertical.png'
import { Container, ContainerDireita, ContainerEsquerda, Logo, Picture, Form, Label, Button, Input, Campo } from './requisition.style';
import { toast } from 'sonner';

function Requisition() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const isLoggedIn = sessionStorage.getItem('isLoggedIn');

  useEffect(() => {
    if (isLoggedIn) {
     toast.success('Usuário já logado');
      navigate('/home');
    }
  }, [isLoggedIn, navigate]);

  const handleSubimit = async (e) => {
    e.preventDefault();
    console.log(`clicou`);

    const loginUser = {
      "email": email,
      "password": password,
    };
    setPassword("");

    try {
      const response = await axios.post(`${process.env.REACT_APP_CONTROLE}/tokens/`, loginUser);
      console.log("requisition", response.data);

      sessionStorage.setItem('token', response.data.token);
      sessionStorage.setItem('isLoggedIn', true);

      let config = {
        method: 'put',
        url: `${process.env.REACT_APP_CONTROLE}/users/atualizarUser/${response.data.id}`,
        data: {
          logado: true,
          ocupado: false,
        }
      };
      await axios(config);
    } catch (error) {
      toast.error('Usuário ou senha inválidos');
      return;
    }

    try {
      await axios.get(`${process.env.REACT_APP_CONTROLE}/users/obterTodosUsers`, {
        headers: {
          autorizacao: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      navigate('/home');
    } catch (error) {
      navigate('/login');
    }
  }



  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  return (
    <Container>
      <ContainerEsquerda>
        <Picture src={teste} />
      </ContainerEsquerda>
      <ContainerDireita>
        <Logo src={logo} />
        <Form onSubmit={handleSubimit}>
          <Campo>
            <Label>E-mail</Label>
            <Input type="text" value={email} onChange={onChangeEmail} placeholder='insira seu login' />
          </Campo>
          <Campo>
            <Label>Senha</Label>
            <Input type="password" value={password} onChange={onChangePassword} placeholder='insira sua senha' />
          </Campo>
          <Button type="submit">Entrar</Button>
        </Form>
      </ContainerDireita>
    </Container>
  );
}
export default Requisition;