import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw; 
`;

export const ContainerEsquerda = styled.div`
  flex: 1;
  width: 50vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(10, 89, 135, 0.60);

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ContainerDireita = styled.div`
  flex: 1; 
  background-color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  width: 70%;
  height: 77%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 0;
    width: 100%;
    height: auto;
  }
`;

export const Campo = styled.div`
  width: 50%;
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;


export const Label = styled.label`
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color: #0A5987;
  width: 100%;
  text-align: left;
  margin-bottom: 2px;
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: 1px solid gray;
  display: inline-block;
  padding: 0 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: gray;
  &:focus {
    outline: none;
    border: none;
    border-bottom: 1px solid gray;
  }
  &::placeholder {
    color: gray;
  }
`;

export const Button = styled.button`
  width: 120px;
  height: 36px;
  border: none;
  margin-top: 15px;
  margin-left: 40%;
  border-radius: 25px;
  background-color: #0A5987;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  transition: all 0.3s;
  &:hover {
    background-color: #2498DC;
  }
`;

export const Picture = styled.img`
  top: 0;
  left: 0;
  width: 50vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
`;

export const Logo = styled.img`
  margin-top: 80px;
  width: 50%;
  height: auto;
  object-fit: div;

  @media (max-width: 768px) {
    margin-bottom: 48px;
    width: 70vw;
  }
`;

export const Texto = styled.p`
  display: flex;
  position: absolute;
`;