import Requisition from './Requisition/requisition';

function Login() {
    return (
        <div>
            <Requisition />
        </div>
    );
}

export default Login;