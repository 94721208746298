import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

function TabelaReduzida({ chamados }) {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Nome</Th>
          <Th>Setor</Th>
          <Th>Ilha</Th>
          <Th>Estação</Th>
          <Th>Suporte</Th>
          <Th>Descrição</Th>
        </Tr>
      </Thead>
      <Tbody>
        {chamados.map((chamado, index) => (
          <Tr key={index}>
            <Td>{chamado.nome}</Td>
            <Td>{chamado.subsetor}</Td>
            <Td>{chamado.ilha}</Td>
            <Td>{chamado.estacaotrabalho}</Td>
            {chamado.equipesuport && <Td>{chamado.equipesuport}</Td>}
            {chamado.descricao && <Td>{chamado.descricao}</Td>}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

export default TabelaReduzida;