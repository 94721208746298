import styled from "styled-components";
import { MdClose } from "react-icons/md";

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  transition: 0.5s;

  &:hover {
    backdrop-filter: blur(10px);
  }
`;

export const ModalContent = styled.div`
  width: ${(props) => props.width || "fit-content"};
  height: ${(props) => props.height || "80vh"};
  background: white;
  padding: 20px;
  border-radius: 10px;
  overflow: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
`;

// Estilo da nova div
export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// Estilo do ícone de fechamento
export const CloseIcon = styled(MdClose)`
  color: red;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
`;
