import React from 'react';
import Form from './formChamado';
import './form.style.css';

import logo from '../../img/SEEC - vertical.png'
import { Image, Title, Footer, BoxPage, BoxForm } from './form.style';

const FormPage = (props) => {
    return (
        <>
            <BoxPage>
                <div style={{ backgroundColor: "#3AA25F", height: "1rem", width: "100%", top: "0", position: "sticky" }}> </div>
                <BoxForm>
                    <div className='box'>
                        <Image src={logo} />
                        <Title>SOLICITAR SUPORTE TÉCNICO</Title>
                        <Form codigoURL={props.codigoURL} />
                    </div>
                </BoxForm>
                <Footer className='footer'>
                    2023 Desenvolvido pelo  <br /> Grupo de Processamento de Dados
                </Footer>
            </BoxPage>

        </>
    );
}

export default FormPage;