import React, { useEffect, useState, useCallback, useRef } from "react";
import { ResultadoBox, BoxChamado } from "../chamado.style";

function Resultados({ criadosHoje, atualizadosHoje }) {
  const [chamCriadosHoje, setChamCriadosHoje] = useState([]);
  const [chamadosAtualizadosHoje, setChamAtualizadosHoje] = useState([]);
  const [saldoDoDia, setSaldoDoDia] = useState();
  const [sinal, setSinal] = useState('');

  const chamResolvido = useRef(0);
  const chamNaoResolvido = useRef(0);
  const chamEmAtendimento = useRef(0);

  const getStatusChamados = useCallback(() => {
    chamResolvido.current = 0;
    chamNaoResolvido.current = 0;
    chamEmAtendimento.current = 0;

    chamadosAtualizadosHoje.forEach((chamado) => {
      if (chamado.status === "CANCELADO") {
        console.log(chamado);
      }

      if (chamado.status === "RESOLVIDO") {
        chamResolvido.current += 1;
      } else if (chamado.status === "NAO RESOLVIDO") {
        chamNaoResolvido.current += 1;
      } else if (chamado.status === "ATENDIMENTO") {
        chamEmAtendimento.current += 1;
      }
    });
    
    setSaldoDoDia(chamResolvido.current + chamNaoResolvido.current);
  }, [chamadosAtualizadosHoje]);

  useEffect(() => {
    setChamCriadosHoje(criadosHoje);
    setChamAtualizadosHoje(atualizadosHoje);
    getStatusChamados();
    
  }, [criadosHoje, atualizadosHoje, getStatusChamados]);

  useEffect(() => {
    getStatusChamados();
    if (chamCriadosHoje.length > saldoDoDia) {
      setSinal("negativo");
    } else if (saldoDoDia > chamCriadosHoje.length) {
      setSinal("positivo");
    } else {
      setSinal('normal');
    }
  }, [chamCriadosHoje, saldoDoDia, getStatusChamados]);

  return (
    <>
      <ResultadoBox>
      <BoxChamado titulo="Criados" quantidade={chamCriadosHoje.length} />
        <BoxChamado titulo="Resolvidos" quantidade={chamResolvido.current} />
        <BoxChamado titulo="Não Resolvidos" quantidade={chamNaoResolvido.current} />
        <BoxChamado titulo="Em Atendimento" quantidade={chamEmAtendimento.current} />
        <BoxChamado
          titulo="Saldo do dia"
          quantidade={saldoDoDia}
          sinal = {sinal}
          type = {'saldoDoDia'}
        />
      </ResultadoBox>
    </>
  );
}

export default Resultados;
