import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import AbrirChamado from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import Home from "../components/Chamado/homeChamado";
import Tabela from "../components/Chamado/Tabela/Tabela";

function Rotas() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" exact element={<AbrirChamado/>} />
                <Route path="/home" exact element={<Home/>} />
                <Route path="/login" exact element={<Login/>} />
                <Route path="/tabela" exact element={<Tabela/>} />
            </Routes>
        </BrowserRouter>
    );
}

export default Rotas;