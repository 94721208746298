import Body from "../../components/Body/Body";
import FormPage from "../../components/Form/formPage";
import PropTypes from "prop-types";

AbrirChamado.propTypes = {
  codigoURL: PropTypes.string,
};

function AbrirChamado() {
  const codigoURL = new URLSearchParams(window.location.search).get(
    "parametro"
  );

  return (
    <>
      <Body>
        <FormPage codigoURL={codigoURL} />
      </Body>
    </>
  );
}

export default AbrirChamado;
