import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    body {
        font-family: 'Roboto', sans-serif;
    }

    button {
        cursor: pointer;
    }

    line {
        width: 100%;
        height: 1.5rem;
        background-color: #3AA25F;
    }

    footer{
      background-color: #0A5987;
      width: 100%;
      height: 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-top: #3AA25F 10px solid;
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      padding: 0 2rem;
      }
`;

export default GlobalStyle;