import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import EdicaoPopUp from "./EdicaoPopUp";
import * as S from "../chamado.style";
import TabelaReduzida from "../Tabela/TabelaReduzida";

function Tabela({ chamados }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [dados, setDados] = useState([]);
  const [autenticado, setAutenticado] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [chamadoAtual, setChamadoAtual] = useState([]);

  const GetChamados = useCallback(() => {
    setDados(chamados);
  }, [chamados]);

  const tiposTabela = [
    "Data",
    "Nome",
    "Setor",
    "Ilha",
    "Estação",
    "Descrição",
    "Suporte",
    "Status",
    "",
    "",
  ];

  function verificarAutenticacao() {
    sessionStorage.getItem("token") === null
      ? setAutenticado(false)
      : setAutenticado(true);
  }

  useEffect(() => {
    GetChamados();
    verificarAutenticacao();

    const intervalId = setInterval(() => {
      GetChamados();
      verificarAutenticacao();
    }, 5000); // 5 segundos

    return () => clearInterval(intervalId);
  }, [chamados, GetChamados]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
  }, [isOpen, chamadoAtual]);

  function alterarChamado(chamado) {
    setChamadoAtual(chamado);
    setIsOpen(true);
  }

  async function finalizarChamado(id) {
    //Iteraçao dos chamados
    let nomeSuporte = null;
    dados.forEach(async (chamado) => {
      if (chamado.id === id) {
        chamado.aberto = false;
        chamado.status = "RESOLVIDO";
        nomeSuporte = chamado.equipesuport;
        await axios.put(
          `${process.env.REACT_APP_CHAMADO}/updateChamado/${id}`, chamado
        );
        // recupera o id do usuario passando o token
        await axios.put(`${process.env.REACT_APP_CONTROLE}/users/atualizarEstadoOcupado/`, { nome: nomeSuporte, ocupado: false });

      } else {
        console.log("não encontrado");
      }
    });
  }

  function renderizarChamados() {
    const chamadosAbertos = dados.filter(
      (chamado) => chamado.status === "ABERTO"
    );
    const chamadosPendentes = dados.filter(
      (chamado) => chamado.status === "ATENDIMENTO"
    );
    const chamadosFinalizados = dados.filter(
      (chamado) => chamado.status === "CANCELADO"
    );
    const chamadosTabela = chamadosAbertos.concat(
      chamadosPendentes,
      chamadosFinalizados
    );
    function fecharPopUp() {
      setIsOpen(false);
      GetChamados();
    }

    if (dados) {
      return (
        <>
          {isOpen && chamadoAtual && (
            <EdicaoPopUp
              chamadoAtual={chamadoAtual}
              fecharPopUp={fecharPopUp}
            />
          )}

          <S.Table>
            <S.Thead>
              <S.Tr tipo="titulo">
                {tiposTabela.map((tipo, i) => (
                  <S.Th key={i} tipo={tipo}>
                    {tipo}
                  </S.Th>
                ))}
              </S.Tr>
            </S.Thead>
            <S.Tbody>
              {chamadosTabela.length === 0 ? (
                <S.Tr>
                  <S.Td
                    colSpan={tiposTabela.length}
                    style={{ textAlign: "center" }}
                  >
                    Nenhum chamado aberto! ✅
                  </S.Td>
                </S.Tr>
              ) : (
                chamadosTabela.map((chamado, i) => (
                  <S.Tr key={i} status={chamado.status}>
                    <S.Td tipo="data">
                      {new Intl.DateTimeFormat("pt-BR", {
                        dateStyle: "short",
                        timeStyle: "short",
                      }).format(new Date(chamado.createdAt))}
                    </S.Td>
                    <S.Td>{chamado.nome}</S.Td>
                    <S.Td tipo="setor">{chamado.subsetor}</S.Td>
                    <S.Td tipo="ilha">{chamado.ilha}</S.Td>
                    <S.Td tipo="estacao">{chamado.estacaotrabalho}</S.Td>
                    <S.Td tipo="descricao">{chamado.descricao}</S.Td>
                    <S.Td tipo="suporte">{chamado.equipesuport}</S.Td>
                    <S.Td tipo="status">{chamado.status}</S.Td>
                    {autenticado && (
                      <>
                        <S.Td>
                          {" "}
                          <S.Button
                            bgColor="blue"
                            onClick={() => alterarChamado(chamado)}
                          >
                            <MdOutlineEdit />
                          </S.Button>{" "}
                        </S.Td>
                        <S.Td>
                          <S.Button
                            bgColor="green"
                            onClick={() => finalizarChamado(chamado.id)}
                          >
                            {" "}
                            <FaCheck />{" "}
                          </S.Button>
                        </S.Td>
                      </>
                    )}
                  </S.Tr>
                ))
              )}
            </S.Tbody>
          </S.Table>
        </>
      );
    }
  }

  return <>{isMobile ? <TabelaReduzida chamados={chamados} finalizarChamado={finalizarChamado} /> : renderizarChamados()}</>;
}

export default Tabela;
