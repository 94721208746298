import styled from 'styled-components';

export const Button = styled.button`
width: ${props => props.width ? props.width : '80%'};
height: 32px;
color: ${props => props.primary ? `#0a5987` : `#FAF9F6`};
background-color: ${props => props.primary ? `#FAF9F6` : `#0a5987`};
stroke: ${props => props.primary ? `#0a5987` : `#0a5987`}; 
font-family: Inter, sans - serif;
font-size: 16px;
font-style: normal;
font-weight: 100;
border: 1px;
border-radius: 5px;
filter: drop-shadow(0px 4px 36px rgba(0, 0, 0, 0.25));
transition: 0.5s;
  &:hover {
  background-color: ${props => props.primary ? `#0a5987` : `#FAF9F6`};
  color: ${props => props.primary ? `#fff` : `#0a5987`};
  cursor: pointer;
  border: 1px solid #0a5987;

}
`;


export const Section = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const Footer = styled.footer`
  background-color: #0a5987;
  border: 1px solid #01436a;
  border-top: #3aa25f 10px solid;
  bottom: 0;
  color: #c8c8c8;
  display: flex;
  flex-direction: column;
  font-size: small;
  font-weight: lighter;
  height: 8%;
  justify-content: center;
  text-align: center;
  width: 100%;
`;
export const Logo = styled.img`
  width: ${props => props.width ? props.width : '15%'};
  height: ${props => props.height ? props.height : 'auto'};;
  margin-left: 2%;
  margin-right: 2%;
`;