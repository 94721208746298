import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import * as S from './form.style.jsx'
import { Button } from "../globalComponents.style";
import { useNavigate } from 'react-router';
import { toast } from 'sonner';

const Form = (props) => {
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [controle, setControle] = useState([]);
  const [estacaoTrabalho, setEstacaoTrabalho] = useState("");

  const buscarControle = useCallback(async function obterControleControlePorId() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_CONTROLE}/listarControle/${estacaoTrabalho}`);
      console.log(response.data);
      setControle(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [estacaoTrabalho, setControle]);


  const schema = yup.object().shape({
    nome: yup.string().required('Seu nome é obrigatório').min(3, 'Nome deve ter no mínimo 3 caracteres'),
    codigo: yup.number().required('Seu código é obrigatório, verifique na etiqueta.').typeError('Digite apenas números'),
    equipamento: yup.string().required('Selecione um equipamento, pois é um item obrigatório'),
    descricao: yup.string().required('Descreva o problema'),
  });

  const { register, handleSubmit, formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (props.codigoURL) {
      setEstacaoTrabalho(Number(props.codigoURL));
    }
    if (estacaoTrabalho) {
      buscarControle();
    }
  }, [props.codigoURL, estacaoTrabalho, buscarControle]);


  async function criarChamado(data) {
    const { nome, descricao, equipamento } = data;

    const chamado = {
      nome: nome,
      setor: controle.setor,
      subsetor: controle.subsetor,
      ilha: controle.ilha,
      estacaotrabalho: controle.baia,
      equipamentocomdefeito: equipamento,
      descricao: descricao,
      equipesuport: "",
    };

    if (controle && equipamento) {
      if (equipamento === "cpu") {
        chamado.equipamentotombo = controle.cputombo;
        chamado.equipamentonumeroserie = controle.cpunumeroserie;
      } else if (equipamento === "monitor1") {
        chamado.equipamentotombo = controle.monitor1tombo;
        chamado.equipamentonumeroserie = controle.monitor1numeroserie;
      } else if (equipamento === "monitor2") {
        chamado.equipamentotombo = controle.monitor2tombo;
        chamado.equipamentonumeroserie = controle.monitor2numeroserie;
      } else if (equipamento === "impressora") {
        chamado.equipamentotombo = controle.impressora;
        chamado.equipamentonumeroserie = "";
      }

    } else {
      chamado.equipamentocomdefeito = "";
      chamado.equipamentotombo = null;
      chamado.equipamentonumeroserie = null;
    }

    try {
      await axios.post(`${process.env.REACT_APP_CHAMADO}/criarChamado`, chamado);
      toast.success('Chamado criado com sucesso');
      navigate('/home');
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <S.Form onSubmit={handleSubmit(criarChamado)}>
      <div className='campoDuplo'>
        <div className='campo'>
          <S.LabeledInput {...register('nome')}
            id="nome"
            type='text'
            label='Nome'
            placeholder='digite seu nome completo'
          />
          {errors.nome ? <S.HelperText>{errors.nome.message}</S.HelperText> : ""}
        </div>
        {props.codigoURL ?
          (<div className='campo'>
            <S.LabeledInput  {...register('codigo')}
              id="codigo"
              type='text'
              label='Código'
              placeholder={props.codigoURL ? props.codigoURL : 'digite seu código'}
              value={props.codigoURL}
            />
            {errors.codigo ? <S.HelperText>{errors.codigo.message}</S.HelperText> : ""}
          </div>) :
          (<div className='campo'>
            <S.LabeledInput  {...register('codigo')}
              id="codigo"
              type='text'
              label='Código'
              placeholder={props.codigoURL ? props.codigoURL : 'digite seu código'}
              onChange={(e) => setEstacaoTrabalho(e.target.value)}
            />
            {errors.codigo ? <S.HelperText>{errors.codigo.message}</S.HelperText> : ""}
          </div>)
        }
      </div>
      <div className='campoDuplo'>
        <div className='campo'>
          <S.Label>Equipamento <span style={{ color: 'red' }}>*</span></S.Label>
          <S.Select
            {...register('equipamento')}
            id="equipamento">
            <option value="">Selecione um equipamento</option>
            <option value="cpu">CPU</option>
            <option value="monitor1">Monitor 1</option>
            <option value="monitor2">Monitor 2</option>
            <option value="impressora">Impressora</option>
          </S.Select>
          {errors.equipamento ? <S.HelperText>{errors.equipamento.message}</S.HelperText> : ""}
        </div>
      </div>

      <div className='campo'>
        <S.LabeledBoxInput  {...register('descricao')}
          id="descricao"
          type='text'
          label='Descrição'
          placeholder='descreva o problema'
        />
        {errors.descricao ? <S.HelperText>{errors.descricao.message}</S.HelperText> : ""}
      </div>

      <Button type="submit" style={{ fontFamily: 'Roboto', fontWeight: 'bold', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}>Enviar solicitação</Button>
    </S.Form>

  );
};

export default Form;