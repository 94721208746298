import React from 'react';
import Rotas from './routes/Routes';
import { Toaster } from 'sonner';

function App() {
  return (
    <>
    <Toaster richColors position='top-right' expand={true}/>
      <Rotas />
    </>
  );
}

export default App;
