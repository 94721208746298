import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

// Estilo da nova div
export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// Estilo do ícone de fechamento
export const CloseIcon = styled(MdClose)`
  color: red;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
`;
