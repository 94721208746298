import React, { useState, useEffect } from "react";

import Logo from "../../img/logotipoSeec.png";
import * as S from "./chamado.style";
import Tabela from "./Tabela/Tabela";
import Resultados from "./Tabela/Resultados";
import { useNavigate } from "react-router-dom";
import { LuLogOut } from "react-icons/lu";
import { IoCreate } from "react-icons/io5";
import axios from "axios";
import { MdAnalytics } from "react-icons/md";

const Header = () => {
  const navigate = useNavigate();

  const [autenticado, setAutenticado] = useState(false);
  const [dados, setDados] = useState([]);
  const [chamCriadosHoje, setChamCriadosHoje] = useState([]);
  const [chamadosAtualizadosHoje, setChamadosAtualizadosHoje] = useState([]);
  const [listaSuporte, setListaSuporte] = useState([]);

  async function GetChamados() {
    const chamadosAberto = await axios.get(
      `${process.env.REACT_APP_CHAMADO}/listarChamadosComStatusAberto`
    );
    const listaSuporte = await axios.get(
      `${process.env.REACT_APP_CONTROLE}/users/listarUsuariosLogados/`
    );
    setListaSuporte(listaSuporte.data);

    if (listaSuporte.data.length > 0 && chamadosAberto.data.length > 0) {
      chamadosAberto.data[0].status = "ATENDIMENTO";
      chamadosAberto.data[0].equipesuport = listaSuporte.data[0].nome;

      //atualizando o chamado
      await axios.put(
        `${process.env.REACT_APP_CHAMADO}/updateChamado/${chamadosAberto.data[0].id}`,
        chamadosAberto.data[0]
      );
      await axios.put(
        `${process.env.REACT_APP_CONTROLE}/users/atualizarEstadoOcupado/`,
        { nome: listaSuporte.data[0].nome, ocupado: true }
      );
    }

    //listar todos os chamados nao finalizados
    const todosChamados = await axios.get(
      `${process.env.REACT_APP_CHAMADO}/listarChamadosAbertos`
    );

    //guarda os chamados nao finalizados nos states
    setDados(todosChamados.data.map((chamado) => ({ ...chamado })));

    const chamadosDeHoje = await axios.get(
      `${process.env.REACT_APP_CHAMADO}/chamadoDeHoje`
    );
    if (Array.isArray(chamadosDeHoje.data)) {
      setChamCriadosHoje(
        chamadosDeHoje.data.map((chamado) => ({ ...chamado }))
      );
    } else {
      console.log("não é array");
    }
    const chamadosPorDataAtualizacao = await axios.get(
      `${process.env.REACT_APP_CHAMADO}/chamadosPorDataAtualizacao`
    );
    if (Array.isArray(chamadosPorDataAtualizacao.data)) {
      setChamadosAtualizadosHoje(
        chamadosPorDataAtualizacao.data.map((chamado) => ({ ...chamado }))
      );
    } else {
      console.log("não é array");
    }
  }

  useEffect(() => {
    GetChamados();
    const fetchChamados = async () => {
      await GetChamados();
      verificarAutenticacao();
    };
    fetchChamados();
    const intervalId = setInterval(fetchChamados, 5000);
    return () => clearInterval(intervalId);
  }, []);

  async function sairSistema() {
    try {
      let token = sessionStorage.getItem("token");

      // recuperar id do usuario logado via token de acesso
      const recuperarId = await axios.post(
        `${process.env.REACT_APP_CONTROLE}/tokens/recuperarIdUser`,
        {
          token: token,
        }
      );

      // alterando propriedades do usuario pelo id
      const user = await axios.put(
        `${process.env.REACT_APP_CONTROLE}/users/atualizarUser/${recuperarId.data.userId}`,
        {
          logado: false,
          ocupado: false,
        }
      );

      const chamados = await axios.get(
        `${process.env.REACT_APP_CHAMADO}/listarChamadosAbertos`
      );

      if (Array.isArray(chamados.data)) {
        // verificar se o usuario logado tem chamados em aberto
        const chamadosUser = chamados.data.filter(
          (chamado) => chamado.equipesuport === user.data.nome
        );
        console.log(chamadosUser.length);
        // se tiver chamados em aberto, alterar o status para aberto e limpar o campo de equipe de suporte
        if (chamadosUser.length > 0) {
          chamadosUser[0].status = "ABERTO";
          chamadosUser[0].equipesuport = "";
          // atualizar o chamado
          await axios.put(
            `${process.env.REACT_APP_CHAMADO}/updateChamado/${chamadosUser[0].id}`,
            chamadosUser[0]
          );
        }
      }

      sessionStorage.clear();
      navigate("/login");
    } catch (error) {
      console.error(error);
    }
  }

  function verificarAutenticacao() {
    sessionStorage.getItem("token") === null
      ? setAutenticado(false)
      : setAutenticado(true);
  }

  useEffect(() => {
    verificarAutenticacao();
  }, [autenticado]);

  return (
    <S.BoxPage>
      <S.Head>
        <S.Menu>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <S.Logo src={Logo} />
            <S.MenuButton color="black" onClick={() => navigate("/")}>
              <IoCreate /> Criar chamado{" "}
            </S.MenuButton>

            {autenticado ? (
              <S.MenuButton color="black" onClick={() => navigate("/")}>
                <MdAnalytics /> Relatórios{" "}
              </S.MenuButton>
            ) : null}
          </div>
          {autenticado ? (
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                width: "5%",
              }}
            >
              <S.MenuButton color="black" onClick={() => sairSistema()}>
                <LuLogOut /> Sair
              </S.MenuButton>
            </div>
          ) : null}
        </S.Menu>
        <S.TitleContainer>
          <S.Title>Grupo de Processamento de Dados</S.Title>
        </S.TitleContainer>
      </S.Head>
      <S.BoxList>
        <S.BoxAux>
          {listaSuporte.map((suporte, index) => (
            <p
              key={index}
              style={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              <div
                style={{
                  backgroundColor: "green",
                  width: "10px",
                  height: "10px",
                  borderRadius: "100%",
                }}
              />
              {suporte.nome}
            </p>
          ))}
        </S.BoxAux>
      </S.BoxList>
      <S.Section>
        <Resultados
          criadosHoje={chamCriadosHoje}
          atualizadosHoje={chamadosAtualizadosHoje}
          getChamados={GetChamados}
        />
        <Tabela chamados={dados} />
      </S.Section>

      <S.Footer>
        2023 Desenvolvido pelo <br /> Grupo de Processamento de Dados
      </S.Footer>
    </S.BoxPage>
  );
};

export default Header;
