import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import * as S from "./form.style.jsx";
import { Button } from "../globalComponents.style";
import PopUp from "../PopUp/popUp.jsx";
import { toast } from "sonner";

const FormEdicao = ({ fecharPopUp, chamadoAtual }) => {
  const modelChamado = {
    nome: chamadoAtual.nome,
    setor: chamadoAtual.setor,
    subsetor: chamadoAtual.subsetor,
    ilha: chamadoAtual.ilha,
    estacaotrabalho: chamadoAtual.estacaotrabalho,
    equipamentocomdefeito: chamadoAtual.equipamentocomdefeito,
    equipamentotombo: chamadoAtual.equipamentotombo,
    equipamentonumeroserie: chamadoAtual.equipamentonumeroserie,
    descricao: chamadoAtual.descricao,
    equipesuport: chamadoAtual.equipesuport,
    status: chamadoAtual.status,
    observacao: chamadoAtual.observacao,
  };

  const [statusList] = useState([
    "ABERTO",
    "RESOLVIDO",
    "NAO RESOLVIDO",
    "CANCELADO",
    "ATENDIMENTO",
  ]);
  const [equipesuportList, setEquipesuportList] = useState([]);
  const [equipamentosList, setEquipamentosList] = useState([]);
  const [nomeSuporteAntigo, setNomeSuporteAntigo] = useState(null);

  const getControllers = useCallback(async () => {
    try {
      // Busca os equipamentos ativos no banco de dados e os coloca em uma lista para serem exibidos no select e serem usados para a validação do formulário
      const equipamentos = await axios.get(
        `${process.env.REACT_APP_CHAMADO}/equipamentos/listarEquipamentosAtivos/`
      );
      setEquipamentosList(
        equipamentos.data.map((equipamento) => equipamento.nome)
      );

      // guarda o nome do usuário que está logado e desocupado no sistema
      const usuariosDisponiveis = await axios.get(
        `${process.env.REACT_APP_CONTROLE}/users/listarUsuariosLogados`
      );
      setEquipesuportList(
        usuariosDisponiveis.data.map(
          (usuariosDisponiveis) => usuariosDisponiveis
        )
      );
    } catch (error) {
      console.error(error);
    }
  }, [setEquipamentosList, setEquipesuportList]);

  useEffect(() => {
    getControllers();
    setNomeSuporteAntigo(chamadoAtual.equipesuport);
  }, [getControllers, chamadoAtual.equipesuport]);

  const alterarEquipeSuporteChamado = async (
    userAntigo,
    userNovo /* Só ID dos usuários */
  ) => {
    try {
      // Altera o status do usuário que vai entrar no chamado para ocupado
      let configUserNovo = {
        method: "put",
        url: `${process.env.REACT_APP_CONTROLE}/users/atualizarUser/${userNovo}`,
        data: {
          ocupado: true,
        },
      };
      await axios(configUserNovo);

      // Altera o status do usuário que está no chamado para desocupado
      let configUserAntigo = {
        method: "put",
        url: `${process.env.REACT_APP_CONTROLE}/users/atualizarUser/${userAntigo}`,
        data: {
          ocupado: false,
        },
      };
      await axios(configUserAntigo);
    } catch (error) {
      console.error(error);
    }
  };

  const { register, handleSubmit } = useForm({
    defaultValues: chamadoAtual,
  });

  const atualizarChamado = async (chamadoAtualizado) => {
    if (
      !chamadoAtualizado.equipesuport ||
      !chamadoAtualizado.equipamentocomdefeito
    ) {
      toast.warning("Selecione uma equipe de suporte!");
    } else {
      try {
        // Busca os usuários logados no sistema e os coloca em uma lista para serem exibidos no select e serem usados para a validação do formulário
        const suporte = await axios.get(
          `${process.env.REACT_APP_CONTROLE}/users/obterTodosUsers`,
          {
            headers: {
              autorizacao: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        const usuarioNovo = suporte.data.find(
          (suporte) => suporte.nome === chamadoAtualizado.equipesuport
        ); // Guarda o usuário que vai entrar no chamado

        const usuarioAntigo = suporte.data.find(
          (suporte) => suporte.nome === nomeSuporteAntigo
        ); // Guarda o usuário que já está no chamado

        alterarEquipeSuporteChamado(usuarioAntigo.id, usuarioNovo.id); // Altera o status do usuário que está no chamado para desocupado e o usuário que vai entrar para ocupado

        await axios.put(
          `${process.env.REACT_APP_CHAMADO}/updateChamado/${chamadoAtual.id}`,
          chamadoAtualizado
        );
        <PopUp width={"10%"} height={"10%"}>
          {" "}
          Chamado atualizado com sucesso!{" "}
        </PopUp>;
        fecharPopUp();
      } catch (error) {
        console.error(error);
      }
    }
  };

  function gerarOpcoes(lista) {
    return lista.map((item) => (
      <option key={item} value={item}>
        {item}
      </option>
    ));
  }

  function gerarListaSuporte(lista) {
    return lista.map((item) => (
      <option key={item.id} value={item.nome}>
        {item.nome}
      </option>
    ));
  }

  return (
    <S.Form onSubmit={handleSubmit(atualizarChamado)}>
      <S.Colunas>
        {Object.entries(modelChamado).map(([campo, valor]) => {
          if (
            campo === "equipamentocomdefeito" ||
            campo === "equipesuport" ||
            campo === "status"
          ) {
            return (
              <S.Campo key={campo}>
                <S.Label htmlFor={campo}>{campo}</S.Label>
                <S.Select {...register(campo)} id={campo} defaultValue={valor}>
                  <option value=""></option>
                  {campo === "equipamentocomdefeito" &&
                    gerarOpcoes(equipamentosList)}
                  {campo === "status" && gerarOpcoes(statusList)}
                  {campo === "equipesuport" &&
                    gerarListaSuporte(equipesuportList)}
                </S.Select>
              </S.Campo>
            );
          } else if (campo !== "equipesuport") {
            return (
              <S.Campo key={campo}>
                <S.LabeledInput
                  {...register(campo)}
                  id={campo}
                  type="text"
                  label={campo}
                  defaultValue={valor}
                />
              </S.Campo>
            );
          } else {
            return <div key={campo}></div>;
          }
        })}
      </S.Colunas>
      <Button type="submit" style={{ width: '100%' }}>
        Alterar chamado
      </Button>
    </S.Form>
  );
};

export default FormEdicao;
