import React, { useState } from "react";
import { CloseIcon, ContentWrapper } from "./icons";

import PopUp from "../../PopUp/popUp";
import FormEdicao from "../../Form/formEdicao";

import { Button } from "../../globalComponents.style";

function EdicaoPopUp({ chamadoAtual, fecharPopUp }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <PopUp fecharPopUp={fecharPopUp} width="45rem">
        <ContentWrapper>
          <CloseIcon onClick={() => setShow(true)} />
          <FormEdicao fecharPopUp={fecharPopUp} chamadoAtual={chamadoAtual} />
        </ContentWrapper>
      </PopUp>
      {show && (
        <PopUp height="20%">
          Tem certeza que deseja sair?
          <Button onClick={fecharPopUp}>sim</Button>
          <Button primary onClick={() => setShow(false)}>
            não
          </Button>
        </PopUp>
      )}
    </>
  );
}

export default EdicaoPopUp;
