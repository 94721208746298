import styled from "styled-components";
import React, { useId, forwardRef } from "react";

export const BoxPage = styled.div`
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;

`;

export const BoxForm = styled.div`
  flex-direction: column;
  width: 100vw;
  height: 93rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    overflow: hidden;
  }
`;

export const Select = styled.select`
  border: none;
  border-bottom: 1px solid #202020;
  color: #a9a9a9;
  display: flex;
  flex-shrink: calc();
  width: 100%;
  margin-top: 2%;
  font-size: small;
  background-color: white;

  &:focus {
    border: none;
    border-bottom: 1px solid gray;
    outline: none;
  }

  &::placeholder {
    color: #a9a9a9;
    font-size: small;
    background-clip: text;
  }
`;

export const LabeledSelect = forwardRef(
  ({ label, type, name, ...props }, ref) => {
    const inputId = useId();

    return (
      <>
        <Container>
          <Label htmlFor={inputId}>{label}</Label>
          <Select ref={ref} id={inputId} type={type} name={name} {...props} />
        </Container>
      </>
    );
  }
);

export const Label = styled.label`
  font-family: "Inter", sans - serif;
  font-size: 16px;
  color: #0a5987;
  width: 100%;
  font-weight: 600;
  text-align: left;
  margin-bottom: 10px;
`;

export const HelperText = styled.label`
  color: red;
  font-size: 0.8rem;
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid #202020;
  display: flex;
  position: sticky;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: #a9a9a9;
  transition: 2s;
  height: 100%;

  &:focus {
    outline: none;
    border: none;
    border-bottom: 1px solid #0a5987;
  }
  &::placeholder {
    color: #a9a9a9;
    font-size: small;
    align-items: start;
  }
`;

export const LabeledInput = forwardRef(
  ({ label, type, name, ...props }, ref) => {
    const inputId = useId();

    return (
      <>
        <Container>
          <Label htmlFor={inputId}>
            {label} <span style={{ color: "red" }}>*</span>
          </Label>
          <Input ref={ref} id={inputId} type={type} name={name} {...props} />
        </Container>
      </>
    );
  }
);

export const LabeledBoxInput = styled(LabeledInput)`
  height: 80px;
  border: 1px solid #202020;
  border-radius: 7px;
  transition: 2s;
  text-align: top;
  font-size: small;

  &::placeholder {
    text-align: top;
    color: #a9a9a9;
    display: flex;
    padding: 10px;
    background: white;
    font-size: 12px;
    background-clip: text;
  }
  &:focus {
    outline: none;
    border: none;
    border: 1px solid #0a5987;
  }
`;

export const Option = styled.option`
  padding: 8px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & {
    margin-right: 20px;
  }
`;

export const Image = styled.img`
  width: 26vw;
  height: auto;
  object-fit: cover;
  border-radius: 5px;

  @media (max-width: 768px) {
    width:50vh;
  }
`;

export const Title = styled.h1`
  font-family: "Inter", sans-serif;
  color: #0a5987;
  font-size: 26px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 25px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;

export const Form = styled.form`
  flex-direction: column;
  width: 90%;
  height: auto;
  display: flexbox;
  align-items: center;
  justify-content: center;
  justify-items: center;

  @media (max-width: 768px) {
    width: 90vw;
    height: 100%;
    padding: 10px;
  }
`;

export const Footer = styled.footer`
  background-color: #0a5987;
  border-top: #3aa25f 10px solid;
  bottom: 0;
  color: #c8c8c8;
  display: flex;
  flex-direction: column;
  font-size: small;
  font-weight: lighter;
  height: 6rem;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
`;

export const Colunas = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: auto;
  height: auto;
  gap: 1rem;
`;

export const Campo = styled.div`
  flex: 1 0 calc(50% - 2rem);
  & > * {
    margin: 0.8rem 0;
  }
`;

const FormStyles = {
  Label,
  BoxPage,
  BoxForm,
  Colunas,
  HelperText,
  Select,
  LabeledSelect,
  LabeledBoxInput,
  LabeledInput,
  Input,
  Form,
  Footer,
  Option,
  Image,
  Title,
};

export default FormStyles;
